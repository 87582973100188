import React, { lazy } from "react";
const BullkEnquiry = lazy(() =>
  import("../Component/Website/BullkEnquiry/BullkEnquiry")
);
const ContactUs = lazy(() =>
  import("../Component/Website/ContactUs/ContactUs")
);
const EditProduct = lazy(() =>
  import("../Component/Admin/Products/Product/EditProduct")
);
const ViewProduct = lazy(() =>
  import("../Component/Admin/Products/Product/ViewProduct")
);
const Checkout = lazy(() => import("../Component/Website/Checkout/Checkout"));
const OrderDetails = lazy(() =>
  import("../Component/Website/MyAccount/OrderDetails")
);
const MyAccount = lazy(() =>
  import("../Component/Website/MyAccount/MyAccount")
);
const Orders = lazy(() => import("../Component/Website/MyAccount/Orders"));
const Address = lazy(() => import("../Component/Website/MyAccount/Address"));
const OrdersList = lazy(() =>
  import("../Component/Admin/Orders/Orders/OrdersList")
);
const Test = lazy(() => import("../Component/Dashboard/Test"));
const Home = lazy(() => import("../Component/Website/Home/Home"));
const SingleProduct = lazy(() =>
  import("../Component/Website/SingleProduct/SingleProduct")
);
const Brand = lazy(() =>
  import("../Component/Admin/Products/Brands/BrandList")
);
const Category = lazy(() =>
  import("../Component/Admin/Products/Category/CategoryList")
);
const Product = lazy(() =>
  import("../Component/Admin/Products/Product/ProductList")
);
const AddProduct = lazy(() =>
  import("../Component/Admin/Products/Product/AddProduct")
);
const Cart = lazy(() => import("../Component/Website/Cart/Cart"));
const PaySuccess = lazy(() =>
  import("../Component/Website/Checkout/PaySuccess")
);
const PayFailed = lazy(() => import("../Component/Website/Checkout/PayFailed"));
const Shop = lazy(() => import("../Component/Website/Shop/Shop"));
const BlogCategory = lazy(() =>
  import("../Component/Admin/Blog/Category/BlogCategoryList")
);
const AboutUs = lazy(() =>
  import("../Component/Website/Pages/AboutUs/AboutUs")
);
const Benefits = lazy(() =>
  import("../Component/Website/Pages/Benefits/Benefits")
);
const Testimonials = lazy(() =>
  import("../Component/Website/Pages/Testimonials/Testimonials")
);
const OurJourney = lazy(() =>
  import("../Component/Website/Pages/OurJourney/OurJourney")
);
const TermsAndConditions = lazy(() =>
  import("../Component/Website/Pages/Policy/TermsAndConditions")
);
const DeliveryInformation = lazy(() =>
  import("../Component/Website/Pages/Policy/DeliveryInformation")
);
const BlogList = lazy(() => import("../Component/Website/Blog/BlogList"));
const BlogDetails = lazy(() => import("../Component/Website/Blog/BlogDetails"));
const ViewOrders = lazy(() =>
  import("../Component/Admin/Orders/Orders/ViewOrders")
);
const Ratings = lazy(() => import("../Component/Admin/Rating/RatingList"));
const Thankyou = lazy(() => import("../Component/Website/ContactUs/Thankyou"));
const CustomerList = lazy(() =>
  import("../Component/Admin/Customers/CustomerList")
);
const CustomerDetails = lazy(() =>
  import("../Component/Admin/Customers/CustomersDetails")
);
const AnOverview = lazy(() => import("../Component/Analytics/AnOverview"));
const AnProducts = lazy(() => import("../Component/Analytics/AnProducts"));
const AnCustomers = lazy(() => import("../Component/Analytics/AnCustomers"));
const AnRevenue = lazy(() => import("../Component/Analytics/AnRevenue"));
const AnOrders = lazy(() => import("../Component/Analytics/AnOrders"));
const AnCoupons = lazy(() => import("../Component/Analytics/AnCoupons"));
const AnMonthlyReports = lazy(() =>
  import("../Component/Analytics/AnMonthlyReports")
);
export const authRoutes = [
  {
    path: "/",
    exact: true,
    element: <Test />,
  },
  {
    path: "/products/brands",
    exact: true,
    element: <Brand />,
  },
  {
    path: "/products/category",
    exact: true,
    element: <Category />,
  },
  {
    path: "/products/products",
    exact: true,
    element: <Product />,
  },
  {
    path: "/products/products/add_product",
    exact: true,
    element: <AddProduct />,
  },
  {
    path: "/products/products/edit_product/:id",
    exact: true,
    element: <EditProduct />,
  },
  {
    path: "/products/products/view_product/:id",
    exact: true,
    element: <ViewProduct />,
  },
  {
    path: "/orders/sales",
    exact: true,
    element: <OrdersList />,
  },
  {
    path: "/blog/category",
    exact: true,
    element: <BlogCategory />,
  },
  {
    path: "/orders/sales/:oid",
    exact: true,
    element: <ViewOrders />,
  },
  {
    path: "/ratings",
    exact: true,
    element: <Ratings />,
  },
  {
    path: "/customers",
    exact: true,
    element: <CustomerList />,
  },
  {
    path: "/customers/customer_details/:id",
    exact: true,
    element: <CustomerDetails />,
  },
  {
    path: "/analytics/overview",
    exact: true,
    element: <AnOverview />,
  },
  {
    path: "/analytics/products",
    exact: true,
    element: <AnProducts />,
  },
  {
    path: "/analytics/revenue",
    exact: true,
    element: <AnRevenue />,
  },
  {
    path: "/analytics/orders",
    exact: true,
    element: <AnOrders />,
  },
  {
    path: "/analytics/coupons",
    exact: true,
    element: <AnCoupons />,
  },
  {
    path: "/analytics/customers",
    exact: true,
    element: <AnCustomers />,
  },
  {
    path: "/analytics/monthly_report",
    exact: true,
    element: <AnMonthlyReports />,
  },
];

export const webRoutes = [
  {
    path: "/",
    exact: true,
    element: <Home />,
  },
  {
    path: "/product/:id",
    exact: true,
    element: <SingleProduct />,
  },
  {
    path: "/products",
    exact: true,
    element: <Shop />,
  },
  {
    path: "/about-us",
    exact: true,
    element: <AboutUs />,
  },
  {
    path: "/benefits-of-naatu-sakkarai",
    exact: true,
    element: <Benefits />,
  },
  {
    path: "/testimonials",
    exact: true,
    element: <Testimonials />,
  },
  {
    path: "/our-journey",
    exact: true,
    element: <OurJourney />,
  },
  {
    path: "/terms-conditions",
    exact: true,
    element: <TermsAndConditions />,
  },
  {
    path: "/delivery-information",
    exact: true,
    element: <DeliveryInformation />,
  },
  {
    path: "/blog",
    exact: true,
    element: <BlogList />,
  },
  {
    path: "/blog/:blog_slug",
    exact: true,
    element: <BlogDetails />,
  },
  {
    path: "/contact-us",
    exact: true,
    element: <ContactUs />,
  },
  {
    path: "/bulk-enquiry",
    exact: true,
    element: <BullkEnquiry />,
  },
  {
    path: "/thank-you",
    exact: true,
    element: <Thankyou />,
  },
];

export const wenAuthRoute = [
  {
    path: "/cart",
    exact: true,
    element: <Cart />,
  },
  {
    path: "/checkout",
    exact: true,
    element: <Checkout />,
  },
  {
    path: "/myaccount/profile",
    exact: true,
    element: <MyAccount />,
  },
  {
    path: "/myaccount/orders",
    exact: true,
    element: <Orders />,
  },
  {
    path: "/myaccount/order-details/:oid",
    exact: true,
    element: <OrderDetails />,
  },
  {
    path: "/myaccount/address",
    exact: true,
    element: <Address />,
  },
  {
    path: "/success",
    exact: true,
    element: <PaySuccess />,
  },
  {
    path: "/failed",
    exact: true,
    element: <PayFailed />,
  },
];
