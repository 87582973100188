import React, { useState } from "react";
import Context from "./index";
import { create } from "../../Api/AuthApi";
import { Form, message } from "antd";
const AdminLoginProvider = ({ children, ...props }) => {
  const [adminLoginLoader, setAdminLoginLoader] = useState(false);
  const [formAdminLogin] = Form.useForm();
  async function adminLogin(data = {}) {
    setAdminLoginLoader(true);
    await create("login.php?action=superadminlogin", data)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          localStorage.setItem("nattu_admin_login", true);
          localStorage.setItem("nattu_admin_token", res?.data?.token);
          window.location.href = "/admin";
          setAdminLoginLoader(false);
        } else {
          message.error(res?.message);
          setAdminLoginLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdminLoginLoader(false);
      });
  }
  return (
    <Context.Provider
      value={{
        ...props,
        adminLogin,
        adminLoginLoader,
        setAdminLoginLoader,
        formAdminLogin,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AdminLoginProvider;
