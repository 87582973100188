import React, { useState } from "react";
import Context from "./index";
import { create } from "../../Api/WebApi";
import { message } from "antd";

const PaymentProvider = ({ children, ...props }) => {
  const [paymentCreateLoader, setPaymentCreateLoader] = useState(false);
  const [paymentSuccessLoader, setPaymentSuccessLoader] = useState(false);
  const [paymentFailedLoader, setPaymentFailedLoader] = useState(false);
  async function createPayment(callback) {
    const source = sessionStorage.getItem("utm_source");
    const medium = sessionStorage.getItem("utm_medium");
    const campaign = sessionStorage.getItem("utm_campaign");
    setPaymentCreateLoader(true);
    const params = new URLSearchParams();
    if (source) params.append("source", JSON.parse(source).value);
    if (medium) params.append("medium", JSON.parse(medium).value);
    if (campaign) params.append("campaign", JSON.parse(campaign).value);
    await create(`orders.php?action=createorder&${params.toString()}`, {})
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          callback(res?.data);
          message.success(res?.message);
          setPaymentCreateLoader(false);
        } else {
          message.error(res?.message);
          setPaymentCreateLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setPaymentCreateLoader(false);
      });
  }
  async function paymentSuccess(
    razorpay_order_id,
    razorpay_payment_id,
    razorpay_signature,
    navigate,
    callbackSuccess
  ) {
    setPaymentSuccessLoader(true);
    await create("orders.php?action=paymentsuccess", {
      razorpay_order_id: razorpay_order_id,
      razorpay_payment_id: razorpay_payment_id,
      razorpay_signature: razorpay_signature,
    })
      .then((res) => {
        if (
          res?.status === 200 &&
          res?.success === true &&
          res?.data?.redirect === "success"
        ) {
          navigate("/success");
          localStorage.setItem(
            "nattu_cart",
            JSON.stringify({ cart_items: [] })
          );
          callbackSuccess();
          message.success(res?.message);
          setPaymentSuccessLoader(false);
        } else {
          if (res?.message === "Payment Already Done") {
            navigate("/success");
            localStorage.setItem(
              "nattu_cart",
              JSON.stringify({ cart_items: [] })
            );
            callbackSuccess();
            message.success("Payment Successfully");
          }
          if (res?.message !== "Payment Already Done") {
            message.error(res?.message);
          }

          setPaymentSuccessLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setPaymentSuccessLoader(false);
      });
  }

  async function paymentFailed(order_id, payment_id, navigate) {
    setPaymentFailedLoader(true);
    await create("orders.php?action=paymentfailed", {
      order_id: order_id,
      payment_id: payment_id,
    })
      .then((res) => {
        if (
          res?.status === 200 &&
          res?.success === true &&
          res?.data?.redirect === "error"
        ) {
          navigate("/failed");
          message.success(res?.message);
          setPaymentFailedLoader(false);
        } else {
          message.error(res?.message);
          setPaymentFailedLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setPaymentFailedLoader(false);
      });
  }
  return (
    <Context.Provider
      value={{
        ...props,
        paymentCreateLoader,
        setPaymentCreateLoader,
        createPayment,
        paymentSuccess,
        paymentSuccessLoader,
        setPaymentSuccessLoader,
        paymentFailedLoader,
        setPaymentFailedLoader,
        paymentFailed,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default PaymentProvider;
