import React, { useState } from "react";
import Context from "./index";
import { create, getAll, remove } from "../../Api/AuthApi";
import { message, Form } from "antd";
const DashboardProvider = ({ children, ...props }) => {
  const [getTotalCountList, setTotalCountList] = useState({});
  const [getTotalCountListLoader, setTotalCountListLoader] = useState(false);
  const [daywiseSalesList, setDaywiseSalesList] = useState({});
  const [daywiseSalesListLoader, setDaywiseSalesListLoader] = useState(false);
  const [monthwiseSalesList, setMonthwiseSalesList] = useState({});
  const [monthwiseSalesListLoader, setMonthwiseSalesListLoader] =
    useState(false);
  const [statewiseSalesList, setStatewiseSalesList] = useState({});
  const [statewiseSalesListLoader, setStatewiseSalesListLoader] =
    useState(false);


  async function fetchTotalCountList() {
    setTotalCountListLoader(true);
    await getAll(`dashboard.php?action=totalcount`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setTotalCountList(res?.data);
          setTotalCountListLoader(false);
        } else {
          setTotalCountListLoader(false);
        }
      })
      .catch((err) => {
        setTotalCountListLoader(false);
      });
  }
  async function fetchDaywiseSalesList() {
    setDaywiseSalesListLoader(true);
    await getAll(`dashboard.php?action=daywisesales`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setDaywiseSalesList(res?.data);
          setDaywiseSalesListLoader(false);
        } else {
          setDaywiseSalesListLoader(false);
        }
      })
      .catch((err) => {
        setDaywiseSalesListLoader(false);
      });
  }
  async function fetchMonthwiseSalesList() {
    setMonthwiseSalesListLoader(true);
    await getAll(`dashboard.php?action=monthwisesales`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setMonthwiseSalesList(res?.data);
          setMonthwiseSalesListLoader(false);
        } else {
          setMonthwiseSalesListLoader(false);
        }
      })
      .catch((err) => {
        setMonthwiseSalesListLoader(false);
      });
  }
  async function fetchStatewiseSalesList() {
    setStatewiseSalesListLoader(true);
    await getAll(`dashboard.php?action=statewisesales`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setStatewiseSalesList(res?.data);
          setStatewiseSalesListLoader(false);
        } else {
          setStatewiseSalesListLoader(false);
        }
      })
      .catch((err) => {
        setStatewiseSalesListLoader(false);
      });
  }
  return (
    <Context.Provider
      value={{
        ...props,
        getTotalCountList,
        setTotalCountList,
        getTotalCountListLoader,
        setTotalCountListLoader,
        fetchTotalCountList,
        fetchDaywiseSalesList,
        daywiseSalesList,
        setDaywiseSalesList,
        daywiseSalesListLoader,
        setDaywiseSalesListLoader,
        monthwiseSalesList,
        setMonthwiseSalesList,
        monthwiseSalesListLoader,
        setMonthwiseSalesListLoader,
        fetchMonthwiseSalesList,
        fetchStatewiseSalesList,
        statewiseSalesList,
        setStatewiseSalesList,
        statewiseSalesListLoader,
        setStatewiseSalesListLoader,
        
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default DashboardProvider;
