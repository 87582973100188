import React, { useState } from "react";
import Context from "./index";
import { getAll, create, getSingle } from "../../Api/AuthApi";
import { message, Form } from "antd";
const AdminProductProvider = ({ children, ...props }) => {
  const [adBrandList, setAdBrandList] = useState([]);
  const [adBrandSingle, setAdBrandSingle] = useState({});
  const [adCategorySingle, setAdCategorySingle] = useState({});
  const [adProductSingle, setAdProductSingle] = useState({});
  const [adCategoryList, setAdCategoryList] = useState([]);
  const [adCategoryListLoader, setAdCategoryListLoader] = useState(false);
  const [adBrandListLoader, setAdBrandListLoader] = useState(false);
  const [adBrandListFilter, setAdBrandListFilter] = useState({
    limit: 10,
    page: 1,
    brand_name: "",
  });
  const [adCategoryListFilter, setAdCategoryListFilter] = useState({
    limit: 10,
    page: 1,
    brand_name: "",
  });
  const [adProductListFilter, setAdProductListFilter] = useState({
    limit: 10,
    page: 1,
    product_name: "",
  });
  const [adBrandPopup, setAdBrandPopup] = useState(false);
  const [adCategoryPopup, setAdCategoryPopup] = useState(false);
  const [adBrandViewPopup, setAdBrandViewPopup] = useState(false);
  const [adCategoryViewPopup, setAdCategoryViewPopup] = useState(false);
  const [adBrandEditPopup, setAdBrandEditPopup] = useState(false);
  const [adCategoryEditPopup, setAdCategoryEditPopup] = useState(false);
  const [adBrandAddLoader, setAdBrandAddLoader] = useState(false);
  const [adCategoryAddLoader, setAdCategoryAddLoader] = useState(false);
  const [adCategoryEditLoader, setAdCategoryEditLoader] = useState(false);
  const [adBrandEditLoader, setAdBrandEditLoader] = useState(false);
  const [adBrandSingleLoader, setAdBrandSingleLoader] = useState(false);
  const [adCategorySingleLoader, setAdCategorySingleLoader] = useState(false);
  const [adProductSingleLoader, setAdProductSingleLoader] = useState(false);
  const [adBrandForm] = Form.useForm();
  const [adCategoryForm] = Form.useForm();
  const [adBrandFormEdit] = Form.useForm();
  const [adCategoryFormEdit] = Form.useForm();
  const [adProductFormEdit] = Form.useForm();
  const [adCatDropdown, setAdCatDropdown] = useState([]);
  const [adCatDropdownLoader, setAdCatDropdownLoader] = useState(false);
  const [adBrandDropdown, setAdBrandDropdown] = useState([]);
  const [adBrandDropdownLoader, setAdBrandDropdownLoader] = useState(false);
  const [adProductAddLoader, setAdProductAddLoader] = useState(false);
  const [adProductUpdateLoader, setAdProductUpdateLoader] = useState(false);
  const [adProductForm] = Form.useForm();
  const [adProductListLoader, setAdProductListLoader] = useState(false);
  const [adProductList, setAdProductList] = useState([]);
  async function adBrandListFetch() {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdBrandListLoader(true);
    await getAll("brand_master.php?action=brandlist", adBrandListFilter)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdBrandList(res?.data);
          setAdBrandListLoader(false);
        } else {
          setAdBrandList([]);
          setAdBrandListLoader(false);
        }
      })
      .catch((err) => {
        setAdBrandList([]);
        setAdBrandListLoader(false);
      });
  }
  async function adBrandAdd(data = {}, image = null, calback) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdBrandAddLoader(true);
    const form_data = new FormData();
    form_data.append("brand_name", data?.brand_name || "");
    form_data.append("brand_status", data?.brand_status === true ? 1 : 0 || 1);
    if (image) {
      form_data.append("brand_image", image);
    }
    form_data.append("brand_description", data?.brand_description || "");
    await create("brand_master.php?action=brandcreate", form_data)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdBrandPopup(false);
          adBrandForm.resetFields();
          calback();
          setAdBrandAddLoader(false);
          adBrandListFetch();
        } else {
          message.error(res?.message);
          setAdBrandAddLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdBrandAddLoader(false);
      });
  }

  async function adBrandSingleFetch(id) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdBrandSingleLoader(true);
    await getSingle(`brand_master.php?action=brandsingle&id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdBrandSingle(res?.data);
          adBrandFormEdit.setFieldsValue({
            ...res?.data,
            brand_status: res?.data?.brand_status === "1" ? true : false,
          });
          setAdBrandSingleLoader(false);
        } else {
          setAdBrandSingle({});
          setAdBrandSingleLoader(false);
        }
      })
      .catch((err) => {
        setAdBrandSingle({});
        setAdBrandSingleLoader(false);
      });
  }

  async function adBrandUpdate(
    data = {},
    image = null,
    id,
    calback = () => {}
  ) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdBrandEditLoader(true);
    const form_data = new FormData();
    form_data.append("brand_name", data?.brand_name || "");
    form_data.append("brand_status", data?.brand_status === true ? 1 : 0);
    if (image) {
      form_data.append("brand_image", image);
    }
    form_data.append("brand_description", data?.brand_description || "");
    await create(
      "brand_master.php?action=brandupdate&brand_id=" + id,
      form_data
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdBrandEditPopup(false);
          adBrandFormEdit.resetFields();
          calback();
          setAdBrandEditLoader(false);
          adBrandListFetch();
        } else {
          message.error(res?.message);
          setAdBrandEditLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdBrandEditLoader(false);
      });
  }

  async function adCategoryListFetch() {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdCategoryListLoader(true);
    await getAll(
      "category_master.php?action=categorylist",
      adCategoryListFilter
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdCategoryList(res?.data);
          setAdCategoryListLoader(false);
        } else {
          setAdCategoryList([]);
          setAdCategoryListLoader(false);
        }
      })
      .catch((err) => {
        setAdCategoryList([]);
        setAdCategoryListLoader(false);
      });
  }

  async function adCatDropDownFetch() {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdCatDropdownLoader(true);
    await getAll("category_master.php?action=categorydropdown")
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdCatDropdown(res?.data);
          setAdCatDropdownLoader(false);
        } else {
          setAdCatDropdown([]);
          setAdCatDropdownLoader(false);
        }
      })
      .catch((err) => {
        setAdCatDropdown([]);
        setAdCatDropdownLoader(false);
      });
  }

  async function adBrandDropDownFetch() {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdBrandDropdownLoader(true);
    await getAll("category_master.php?action=branddropdown")
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdBrandDropdown(res?.data);
          setAdBrandDropdownLoader(false);
        } else {
          setAdBrandDropdown([]);
          setAdBrandDropdownLoader(false);
        }
      })
      .catch((err) => {
        setAdBrandDropdown([]);
        setAdBrandDropdownLoader(false);
      });
  }

  async function adCategoryAdd(data = {}, image = null, calback) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdCategoryAddLoader(true);
    const form_data = new FormData();
    form_data.append("category_name", data?.category_name || "");
    form_data.append("category_parent_id", data?.category_parent_id || "");
    form_data.append("brand_id", data?.brand_id || "");
    form_data.append(
      "category_status",
      data?.category_status == true ? 1 : 0 || 1
    );
    form_data.append("order_no", data?.order_no || 0);
    if (image) {
      form_data.append("category_image", image);
    }
    form_data.append("category_description", data?.category_description || "");
    await create("category_master.php?action=categorycreate", form_data)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdCategoryPopup(false);
          adCategoryForm.resetFields();
          calback();
          setAdCategoryAddLoader(false);
          adCategoryListFetch();
          adCatDropDownFetch();
        } else {
          message.error(res?.message);
          setAdCategoryAddLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdCategoryAddLoader(false);
      });
  }
  async function adCategoryUpdate(data = {}, image = null, id, calback) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdCategoryEditLoader(true);
    const form_data = new FormData();
    form_data.append("category_name", data?.category_name || "");
    form_data.append("category_parent_id", data?.category_parent_id || "");
    form_data.append("brand_id", data?.brand_id || "");
    form_data.append("category_status", data?.category_status == true ? 1 : 0);
    form_data.append("order_no", data?.order_no || 0);
    if (image) {
      form_data.append("category_image", image);
    }
    form_data.append("category_description", data?.category_description || "");
    await create(
      "category_master.php?action=categoryupdate&category_id=" + id,
      form_data
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdCategoryEditPopup(false);
          adCategoryFormEdit.resetFields();
          calback();
          setAdCategoryEditLoader(false);
          adCategoryListFetch();
          adCatDropDownFetch();
        } else {
          message.error(res?.message);
          setAdCategoryEditLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdCategoryEditLoader(false);
      });
  }

  async function adCategorySingleFetch(id) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdCategorySingleLoader(true);
    await getSingle(`category_master.php?action=categorysingle&id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdCategorySingle(res?.data);
          adCategoryFormEdit.setFieldsValue({
            ...res?.data,
            category_status: res?.data?.category_status == "1" ? true : false,
            category_parent_id: res?.data?.parent_category_id || "",
            category_parent_id: res?.data?.parent_category_id || "",
          });
          setAdCategorySingleLoader(false);
        } else {
          setAdCategorySingle({});
          setAdCategorySingleLoader(false);
        }
      })
      .catch((err) => {
        setAdCategorySingle({});
        setAdCategorySingleLoader(false);
      });
  }

  async function adProductAdd(
    data = {},
    image = [],
    calback = () => {},
    navigate
  ) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdProductAddLoader(true);
    const form_data = new FormData();
    form_data.append("product_name", data?.product_name || "");
    form_data.append("product_description", data?.product_description || "");
    form_data.append(
      "category_id",
      data?.category_id?.map((e) => e).join("|") || ""
    );
    form_data.append("brand_id", data?.brand_id || "");
    form_data.append("product_label", data?.product_label || "");
    form_data.append("product_tagname", data?.product_tagname || "");
    form_data.append("product_sku", data?.product_sku || "");
    form_data.append("product_gst", data?.product_gst || 0);
    form_data.append("mrp", data?.mrp || 0);
    form_data.append("sp", data?.sp || 0);
    form_data.append("s_weight", data?.s_weight || 0);
    form_data.append("s_length", data?.s_length || 0);
    form_data.append("s_width", data?.s_width || 0);
    form_data.append("s_height", data?.s_height || 0);
    form_data.append("best_order", data?.best_order || 0);
    form_data.append("feature_order", data?.feature_order || 0);
    form_data.append("promo_order", data?.promo_order || 0);
    form_data.append("product_order", data?.product_order || 0);
    form_data.append("meta_title", data?.meta_title || "");
    form_data.append("meta_description", data?.meta_description || "");
    form_data.append("meta_keywords", data?.meta_keywords || "");

    form_data.append("stock_status", data?.stock_status == true ? 1 : 0);
    form_data.append("free_shipping", data?.free_shipping == true ? 1 : 0);
    form_data.append(
      "our_range_status",
      data?.our_range_status == true ? 1 : 0
    );
    form_data.append("best_sellers", data?.best_sellers == true ? 1 : 0);
    form_data.append(
      "feature_products",
      data?.feature_products == true ? 1 : 0
    );
    form_data.append("promo_products", data?.promo_products == true ? 1 : 0);
    form_data.append("display_website", data?.display_website == true ? 1 : 0);
    form_data.append("combo_product", data?.combo_product == true ? 1 : 0);
    form_data.append("publish_status", 1);
    if (image) {
      for (let i = 0; i < image.length; i++) {
        form_data.append("product_gallery[]", image[i]);
      }
    }
    if (data?.specification?.length > 0) {
      const spec_name = data?.specification?.map((el) => el?.name).join("|");
      const spec_value = data?.specification?.map((el) => el?.value).join("|");
      form_data.append("specification_name", spec_name);
      form_data.append("specification_value", spec_value);
    }
    await create("product_master.php?action=productcreate", form_data)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          adProductForm.resetFields();
          calback();
          setAdProductAddLoader(false);
          navigate("/admin/products/products");
        } else {
          message.error(res?.message);
          setAdProductAddLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdProductAddLoader(false);
      });
  }

  async function adProductListFetch() {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdProductListLoader(true);
    await getAll("product_master.php?action=productlist", adProductListFilter)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdProductList(res?.data);
          setAdProductListLoader(false);
        } else {
          setAdProductList([]);
          setAdProductListLoader(false);
        }
      })
      .catch((err) => {
        setAdProductList([]);
        setAdProductListLoader(false);
      });
  }

  async function adProductSingleFetch(id) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdProductSingleLoader(true);
    await getSingle(`product_master.php?action=productsingle&product_id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdProductSingle(res?.data);
          const specification = res?.data?.specification_name?.split("|");
          const spec = [];
          if (specification?.length > 0) {
            specification?.map((el, index) => {
              spec.push({
                name: el,
                value: res?.data?.specification_value?.split("|")[index],
              });
            });
          }
          adProductFormEdit.setFieldsValue({
            ...res?.data,
            specification: spec,
            our_range_status: res?.data?.our_range_status == "1" ? true : false,
            best_sellers: res?.data?.best_sellers == "1" ? true : false,
            feature_products: res?.data?.feature_products == "1" ? true : false,
            promo_products: res?.data?.promo_products == "1" ? true : false,
            display_website: res?.data?.display_website == "1" ? true : false,
            product_status: res?.data?.product_status == "1" ? true : false,
            free_shipping: res?.data?.free_shipping == "1" ? true : false,
            stock_status: res?.data?.stock_status == "1" ? true : false,
            combo_product: res?.data?.combo_product == "1" ? true : false,
            category_id: res?.data?.category_id
              ?.split("|")
              .map((catId) => catId),
          });
          setAdProductSingleLoader(false);
        } else {
          setAdProductSingle({});
          setAdProductSingleLoader(false);
        }
      })
      .catch((err) => {
        setAdProductSingle({});
        setAdProductSingleLoader(false);
      });
  }

  async function adProductUpdate(
    data = {},
    image = [],
    id,
    calback = () => {},
    navigate
  ) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdProductUpdateLoader(true);
    const form_data = new FormData();
    form_data.append("product_name", data?.product_name || "");
    form_data.append("product_slug", data?.product_slug || "");
    form_data.append("product_description", data?.product_description || "");
    form_data.append(
      "category_id",
      data?.category_id?.map((el) => el).join("|") || ""
    );
    form_data.append("brand_id", data?.brand_id || "");
    form_data.append("product_label", data?.product_label || "");
    form_data.append("product_tagname", data?.product_tagname || "");
    form_data.append("product_sku", data?.product_sku || "");
    form_data.append("product_gst", data?.product_gst || 0);
    form_data.append("mrp", data?.mrp || 0);
    form_data.append("sp", data?.sp || 0);
    form_data.append("s_weight", data?.s_weight || 0);
    form_data.append("s_length", data?.s_length || 0);
    form_data.append("s_width", data?.s_width || 0);
    form_data.append("s_height", data?.s_height || 0);
    form_data.append("best_order", data?.best_order || 0);
    form_data.append("feature_order", data?.feature_order || 0);
    form_data.append("promo_order", data?.promo_order || 0);
    form_data.append("product_order", data?.product_order || 0);
    form_data.append("meta_title", data?.meta_title || "");
    form_data.append("meta_description", data?.meta_description || "");
    form_data.append("meta_keywords", data?.meta_keywords || "");

    form_data.append("stock_status", data?.stock_status == true ? 1 : 0);
    form_data.append("free_shipping", data?.free_shipping == true ? 1 : 0);
    form_data.append(
      "our_range_status",
      data?.our_range_status == true ? 1 : 0
    );
    form_data.append("best_sellers", data?.best_sellers == true ? 1 : 0);
    form_data.append(
      "feature_products",
      data?.feature_products == true ? 1 : 0
    );
    form_data.append("promo_products", data?.promo_products == true ? 1 : 0);
    form_data.append("display_website", data?.display_website == true ? 1 : 0);
    form_data.append("combo_product", data?.combo_product == true ? 1 : 0);
    form_data.append("publish_status", 1);
    if (image) {
      for (let i = 0; i < image.length; i++) {
        form_data.append("product_gallery[]", image[i]);
      }
    }
    if (data?.specification?.length > 0) {
      const spec_name = data?.specification?.map((el) => el?.name).join("|");
      const spec_value = data?.specification?.map((el) => el?.value).join("|");
      form_data.append("specification_name", spec_name);
      form_data.append("specification_value", spec_value);
    }
    await create(
      "product_master.php?action=productupdate&product_id=" + id,
      form_data
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          adProductFormEdit.resetFields();
          calback();
          setAdProductUpdateLoader(false);
          navigate("/admin/products/products");
        } else {
          message.error(res?.message);
          setAdProductUpdateLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdProductUpdateLoader(false);
      });
  }

  async function removeImage(image_id, product_id) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    await create(
      "product_master.php?action=productimage&image_id=" +
        image_id +
        "&product_id=" +
        product_id
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          adProductSingleFetch(product_id);
          message.success(res?.message);
        } else {
          message.error(res?.message);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  }
  return (
    <Context.Provider
      value={{
        ...props,
        adBrandListFetch,
        adBrandList,
        setAdBrandList,
        adBrandListLoader,
        setAdBrandListLoader,
        adBrandListFilter,
        setAdBrandListFilter,
        adBrandPopup,
        setAdBrandPopup,
        adBrandAddLoader,
        setAdBrandAddLoader,
        adBrandAdd,
        adBrandForm,
        adBrandSingleFetch,
        adBrandSingle,
        adBrandSingleLoader,
        setAdBrandSingleLoader,
        adBrandViewPopup,
        setAdBrandViewPopup,
        adBrandEditPopup,
        setAdBrandEditPopup,
        adBrandFormEdit,
        adBrandUpdate,
        adBrandEditLoader,
        setAdBrandEditLoader,
        adCategoryListFetch,
        adCategoryListFilter,
        setAdCategoryListFilter,
        adCategoryList,
        setAdCategoryList,
        adCategoryListLoader,
        setAdCategoryListLoader,
        adCatDropDownFetch,
        adBrandDropDownFetch,
        adCatDropdown,
        setAdCatDropdown,
        adCatDropdownLoader,
        setAdCatDropdownLoader,
        adBrandDropdown,
        setAdBrandDropdown,
        adBrandDropdownLoader,
        setAdBrandDropdownLoader,
        adCategoryAdd,
        adCategoryForm,
        adCategoryAddLoader,
        setAdCategoryAddLoader,
        adCategoryPopup,
        setAdCategoryPopup,
        adCategoryFormEdit,
        adCategoryUpdate,
        adCategoryEditLoader,
        setAdCategoryEditLoader,
        adCategoryEditPopup,
        setAdCategoryEditPopup,
        adCategorySingleFetch,
        adCategorySingleLoader,
        setAdCategorySingleLoader,
        adCategorySingle,
        setAdCategorySingle,
        adCategoryViewPopup,
        setAdCategoryViewPopup,
        adProductForm,
        adProductAdd,
        adProductAddLoader,
        setAdProductAddLoader,
        adProductListFetch,
        adProductListLoader,
        setAdProductListLoader,
        adProductList,
        setAdProductList,
        adProductListFilter,
        setAdProductListFilter,
        adProductSingle,
        setAdProductSingle,
        adProductSingleLoader,
        setAdProductSingleLoader,
        adProductFormEdit,
        adProductSingleFetch,
        adProductUpdate,
        adProductUpdateLoader,
        setAdProductUpdateLoader,
        removeImage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AdminProductProvider;
