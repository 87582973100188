import Routes from "./Routes";
import AdminLoginProvider from "./Provider/AdminLoginProvider/AdminLoginProvider";
import AdminProductProvider from "./Provider/AdminProductProvider/AdminProductProvider";
import WebHomeProvider from "./Provider/WebHomeProvider/WebHomeProvider";
import WebMyAccountProvider from "./Provider/WebMyAccountProvider/WebMyAccountProvider";
import CartProvider from "./Provider/CartProvider/CartProvider";
import PaymentProvider from "./Provider/PaymentProvider/PaymentProvider";
import AdminOrdersContext from "./Provider/AdminOrdersProvider/AdminOrdersProvider";
import RatingsProvider from "./Provider/Ratings/RatingsProvider";
import DashboardContext from "./Provider/DashboardProvider/DashboardProvider";
import AdminCustomerProvider from "./Provider/AdminCustomerProvider/AdminCustomerProvider";
import AdminAnalyticsProvider from "./Provider/AdminAnalyticsProvider/AdminAnalyticsProvider";
function App() {
  return (
    <div className="App">
      <AdminAnalyticsProvider>
        <AdminCustomerProvider>
          <DashboardContext>
            <RatingsProvider>
              <AdminOrdersContext>
                <PaymentProvider>
                  <CartProvider>
                    <WebMyAccountProvider>
                      <WebHomeProvider>
                        <AdminProductProvider>
                          <AdminLoginProvider>
                            <Routes />
                          </AdminLoginProvider>
                        </AdminProductProvider>
                      </WebHomeProvider>
                    </WebMyAccountProvider>
                  </CartProvider>
                </PaymentProvider>
              </AdminOrdersContext>
            </RatingsProvider>
          </DashboardContext>
        </AdminCustomerProvider>
      </AdminAnalyticsProvider>
    </div>
  );
}

export default App;
