import React, { useState } from "react";
import Context from "./index";
import { create, getAll } from "../../Api/WebApi";
import { Form, message } from "antd";
const WebHomeProvider = ({ children, ...props }) => {
  const [webHomeLoader, setWebHomeLoader] = useState(false);
  const [webHomeData, setWebHomeData] = useState({});
  const [webSingleProductData, setWebSingleProductData] = useState({});
  const [webSingleProductLoader, setWebSingleProductLoader] = useState(false);
  const [shopProductList, setShopProductList] = useState([]);
  const [shopProductListLoader, setShopProductListLoader] = useState(false);
  const [shopCategoryList, setShopCategoryList] = useState([]);
  const [shopCategoryListLoader, setShopCategoryListLoader] = useState(false);
  const [shopProductListFilter, setShopProductListFilter] = useState({
    limit: 8,
    page: 1,
    q: "",
  });
  const [blogList, setBlogList] = useState([]);
  const [blogListLoader, setBlogListLoader] = useState(false);
  const [blogListFilter, setBlogListFilter] = useState({
    limit: 9,
    page: 1,
    q: "",
  });
  const [blogSingle, setBlogSingle] = useState({});
  const [blogSingleLoader, setBlogSingleLoader] = useState(false);
  const [productMenuList, setProductMenuList] = useState([]);
  const [pincodeCheckLoader, setPincodeCheckLoader] = useState(false);
  const [pincodeCheckMsg, setPincodeCheckMsg] = useState({});
  const [productSearchLoader, setProductSearchLoader] = useState(false);
  const [productSearchPopup, setProductSearchPopup] = useState(false);
  const [productSearchList, setProductSearchList] = useState([]);
  const [tabProductList, setTabProductList] = useState([]);
  const [tabProductListLoader, setTabProductListLoader] = useState(false);
  const [seoMetaList, setSeoMetaList] = useState([]);
  const [seoMetaListLoader, setSeoMetaListLoader] = useState(false);
  const [getReviewListLoader, setGetReviewListLoader] = useState(false);
  const [getReviewList, setGetReviewList] = useState([]);
  const [contactForm] = Form.useForm();
  const [contactFormLoader, setContactFormLoader] = useState(false);

  async function submitForm(data, type = "contact", navigate = null) {
    if (!data) return;
    data.type = type;
    setContactFormLoader(true);
    try {
      const response = await fetch(
        "https://greaterdhan.com/mailapi/contact_form.php?action=contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        message.error("Something went wrong");
        setContactFormLoader(false);
        return;
      }

      const result = await response.json();
      if (result[0] === "success") {
        contactForm.resetFields();
        message.success(
          "Thank you for contacting us. We will get back to you soon."
        );
        setContactFormLoader(false);
        if (navigate) {
          navigate("/thank-you", { replace: true });
        }
      }
    } catch (err) {
      setContactFormLoader(false);
      message.error("Something went wrong");
    }
  }
  async function getReview(id) {
    if (!id) return;
    setGetReviewListLoader(true);
    await getAll(`shop.php?action=getreview&product_id=${id}`).then((res) => {
      if (res?.status === 200 && res?.success === true) {
        setGetReviewList(res?.data);
        setGetReviewListLoader(false);
      } else {
        setGetReviewListLoader(false);
      }
    });
  }
  async function seoMetaFetch() {
    setSeoMetaListLoader(true);
    await getAll(`web.php?action=seometa`).then((res) => {
      if (res?.status === 200 && res?.success === true) {
        setSeoMetaList(res?.data);
        setSeoMetaListLoader(false);
      } else {
        setSeoMetaListLoader(false);
      }
    });
  }
  async function tabProduct() {
    setTabProductListLoader(true);
    await getAll(`web.php?action=tabproduct`).then((res) => {
      if (res?.status === 200 && res?.success === true) {
        setTabProductList(res?.data);
        setTabProductListLoader(false);
      } else {
        setTabProductListLoader(false);
      }
    });
  }
  async function fetchProductSearch(productSearch) {
    if (productSearch === "") {
      setProductSearchList([]);
      return;
    }
    setProductSearchLoader(true);
    await getAll("shop.php?action=productsearch&q=" + productSearch)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setProductSearchList(res?.data);
          setProductSearchLoader(false);
        } else {
          setProductSearchList([]);
          setProductSearchLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setProductSearchLoader(false);
      });
  }
  async function pincodeData(pincode) {
    setPincodeCheckLoader(true);
    await getAll("web.php?action=pincodechecker&pincode=" + pincode)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setPincodeCheckMsg(res?.data);
          setPincodeCheckLoader(false);
        } else {
          message.error("This pincode is not available");
          setPincodeCheckLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setPincodeCheckLoader(false);
      });
  }
  async function getWebHomeData() {
    setWebHomeLoader(true);
    await getAll("web.php?action=home")
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setWebHomeData(res?.data);
          setWebHomeLoader(false);
        } else {
          // message.error(res?.message);
          setWebHomeLoader(false);
        }
      })
      .catch((err) => {
        // message.error("Something went wrong");
        setWebHomeLoader(false);
      });
  }
  async function getWebSingleProduct(id, redirect = null) {
    setWebSingleProductLoader(true);
    await getAll(`web.php?action=singleproduct&product_slug=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setWebSingleProductData(res?.data);
          setWebSingleProductLoader(false);
          if (!res?.data?.product_id) {
            redirect();
          }
        } else {
          // message.error(res?.message);
          setWebSingleProductLoader(false);
        }
      })
      .catch((err) => {
        // message.error("Something went wrong");
        setWebSingleProductLoader(false);
      });
  }
  const calculateOfferPercentage = (mrp = 0, sp = 0) => {
    let discount = mrp - sp;
    let offerPercentage = ((discount / mrp) * 100).toFixed(0);
    return offerPercentage;
  };

  async function fetchShopPage(category_id = null) {
    setShopProductListLoader(true);
    await getAll("shop.php?action=shop", {
      category_id: category_id,
      ...shopProductListFilter,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setShopProductList(res?.data);
          setShopProductListLoader(false);
        } else {
          setShopProductList([]);
          setShopProductListLoader(false);
        }
      })
      .catch((err) => {
        setShopProductList([]);
        setShopProductListLoader(false);
      });
  }
  async function fetchShopCategory() {
    setShopCategoryListLoader(true);
    await getAll("shop.php?action=shopcategory", {})
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setShopCategoryList(res?.data);
          setShopCategoryListLoader(false);
        } else {
          setShopCategoryList([]);
          setShopCategoryListLoader(false);
        }
      })
      .catch((err) => {
        setShopCategoryList([]);
        setShopCategoryListLoader(false);
      });
  }

  async function fetchBlogList() {
    setBlogListLoader(true);
    await getAll("web.php?action=bloglist", blogListFilter)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setBlogList(res?.data);
          setBlogListLoader(false);
        } else {
          setBlogList([]);
          setBlogListLoader(false);
        }
      })
      .catch((err) => {
        setBlogList([]);
        setBlogListLoader(false);
      });
  }
  async function fetchBlogSingle(blog_slug, redirect = null) {
    setBlogSingleLoader(true);
    await getAll("web.php?action=blogsingle&blog_slug=" + blog_slug)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setBlogSingle(res?.data);
          setBlogSingleLoader(false);
          if (!res?.data?.blog_id) {
            redirect();
          }
        } else {
          setBlogSingle([]);
          setBlogSingleLoader(false);
        }
      })
      .catch((err) => {
        setBlogSingle([]);
        setBlogSingleLoader(false);
      });
  }

  function blogDateFormat(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  }

  async function fetchProductMenuList() {
    await getAll("shop.php?action=productlistmenu")
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setProductMenuList(res?.data);
        } else {
          setProductMenuList([]);
        }
      })
      .catch((err) => {
        setProductMenuList([]);
      });
  }
  return (
    <Context.Provider
      value={{
        ...props,
        webHomeLoader,
        setWebHomeLoader,
        webHomeData,
        setWebHomeData,
        getWebHomeData,
        calculateOfferPercentage,
        getWebSingleProduct,
        webSingleProductData,
        setWebSingleProductData,
        webSingleProductLoader,
        setWebSingleProductLoader,
        fetchShopPage,
        shopProductListFilter,
        setShopProductListFilter,
        shopProductList,
        setShopProductList,
        shopProductListLoader,
        setShopProductListLoader,
        fetchShopCategory,
        shopCategoryList,
        setShopCategoryList,
        shopCategoryListLoader,
        setShopCategoryListLoader,
        fetchBlogList,
        blogList,
        setBlogList,
        blogListLoader,
        setBlogListLoader,
        blogListFilter,
        setBlogListFilter,
        fetchBlogSingle,
        blogSingle,
        setBlogSingle,
        blogSingleLoader,
        setBlogSingleLoader,
        blogDateFormat,
        productMenuList,
        setProductMenuList,
        fetchProductMenuList,
        pincodeCheckLoader,
        setPincodeCheckLoader,
        pincodeCheckMsg,
        setPincodeCheckMsg,
        pincodeData,
        productSearchLoader,
        setProductSearchLoader,
        productSearchPopup,
        setProductSearchPopup,
        productSearchList,
        setProductSearchList,
        fetchProductSearch,
        tabProductList,
        setTabProductList,
        tabProductListLoader,
        setTabProductListLoader,
        tabProduct,
        seoMetaList,
        setSeoMetaList,
        seoMetaListLoader,
        setSeoMetaListLoader,
        seoMetaFetch,
        getReview,
        getReviewListLoader,
        setGetReviewListLoader,
        getReviewList,
        setGetReviewList,
        contactForm,
        submitForm,
        contactFormLoader,
        setContactFormLoader,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default WebHomeProvider;
