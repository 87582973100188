//get all
export async function getAll(url, params={}, token = localStorage.getItem("nattu_admin_token")) {
  const queryString = new URLSearchParams(params).toString();
  const fullUrl = `${process.env.REACT_APP_API_URL}${url}&${queryString}`;
  const response = await fetch(fullUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
}
//get single
export async function getSingle(url, token = localStorage.getItem("nattu_admin_token")) {
  const response = await fetch(process.env.REACT_APP_API_URL + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
}
//create
export async function create(url, data, token=localStorage.getItem("nattu_admin_token")) {
  let body;
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  if (data instanceof FormData) {
    body = data;
  } else {
    body = JSON.stringify(data);
    headers["Content-Type"] = "application/json";
  }

  const response = await fetch(process.env.REACT_APP_API_URL + url, {
    method: "POST",
    headers: headers,
    body: body,
  });

  const dataRes = await response.json();
  return dataRes;
}
//update
export async function update(url, data) {
  const response = await localStorage
    .getItem("nattu_admin_token")
    .then(async (token) => {
      if (!token) return;
      const response = await fetch(process.env.REACT_APP_API_URL + url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      const dataRes = await response.json();
      return dataRes;
    });
  return response;
}
//delete
export async function remove(url) {
  const response = await localStorage
    .getItem("nattu_admin_token")
    .then(async (token) => {
      if (!token) return;
      const response = await fetch(process.env.REACT_APP_API_URL + url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const dataRes = await response.json();
      return dataRes;
    });
  return response;
}
