import React, { Suspense, lazy, useLayoutEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider, Spin } from "antd";
import { Custom } from "../Config/ThemeConfig";
import { AuthRouter, Redirect } from "./AuthRouter";
import Dashboard from "../Layouts/Dashboard";
import { authRoutes, webRoutes, wenAuthRoute } from "./Route";
import { WebAuthRouter } from "./WebAuthRouter";
import { useLocation } from "react-router-dom";
const AdminLogin = lazy(() => import("../Component/Admin/Login/Login"));
const WebLayout = lazy(() => import("../Layouts/WebLayout"));
const NoPage = lazy(() => import("../Layouts/NoPage"));
const Index = () => {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(500, 0);
    }, [location.pathname]);
    return children;
  };
  // basename="/nattu"
  return (
    <ConfigProvider theme={{ token: Custom }}>
      <Router>
        <Wrapper>
          <Suspense
            fallback={
              <div className="splish_screen">
                <Spin />
              </div>
            }
          >
            <Routes>
              <Route
                path="/login"
                element={
                  <Redirect>
                    <div>Login</div>
                  </Redirect>
                }
              />
              <Route
                path="/admin/login"
                element={
                  <Redirect>
                    <AdminLogin />
                  </Redirect>
                }
              />

              <Route path="/" element={<WebLayout />}>
                {webRoutes?.map((e, i) => (
                  <Route key={i} path={e?.path} element={e?.element} />
                ))}
                {wenAuthRoute?.map((e, i) => (
                  <Route
                    key={i}
                    path={e?.path}
                    element={<WebAuthRouter>{e?.element}</WebAuthRouter>}
                  />
                ))}
                <Route path="404" element={<NoPage />} />
                <Route path="*" element={<NoPage />} />
              </Route>
              <Route
                path="/"
                element={
                  <AuthRouter>
                    <Dashboard />
                  </AuthRouter>
                }
              >
                {authRoutes !== null &&
                  authRoutes?.map((item, index) => (
                    <Route
                      key={index}
                      path={"admin" + item?.path}
                      element={item?.element}
                    />
                  ))}
                <Route path="404" element={<NoPage />} />
                <Route path="*" element={<NoPage />} />
              </Route>
              <Route path="404" element={<NoPage />} />
              <Route path="*" element={<NoPage />} />
            </Routes>
          </Suspense>
        </Wrapper>
      </Router>
    </ConfigProvider>
  );
};

export default Index;
