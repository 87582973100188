import React, { useState } from "react";
import Context from "./index";
import { create, getAll, remove } from "../../Api/AuthApi";
import { message, Form } from "antd";
const AdminCustomerProvider = ({ children, ...props }) => {
  const [adCustomerList, setAdCustomerList] = useState({});
  const [adCustomerListLoader, setAdCustomerListLoader] = useState(false);
  const [adCustomerListFilter, setAdCustomerListFilter] = useState({
    limit: 10,
    page: 1,
    customer_name: "",
  });
  const [adCustomerOrderList, setAdCustomerOrderList] = useState({});
  const [adCustomerListOrderLoader, setAdCustomerListOrderLoader] =
    useState(false);
  const [adCustomerListOrderFilter, setAdCustomerListOrderFilter] = useState({
    limit: 10,
    page: 1,
  });
  const [adCustomerOverview, setAdCustomerOverview] = useState({});
  const [adCustomerOverviewLoader, setAdCustomerOverviewLoader] =
    useState(false);
  async function fetchAdCustomerOverview(id) {
    setAdCustomerOverviewLoader(true);
    await getAll(
      `customers.php?action=customerdetails&id=${id}`,
      adCustomerListOrderFilter
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdCustomerOverview(res?.data);
          setAdCustomerOverviewLoader(false);
        } else {
          setAdCustomerOverviewLoader(false);
        }
      })
      .catch((err) => {
        setAdCustomerOverviewLoader(false);
      });
  }
  async function fetchAdCustomerOrderList(id) {
    setAdCustomerListOrderLoader(true);
    await getAll(
      `customers.php?action=orderlist&id=${id}`,
      adCustomerListOrderFilter
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdCustomerOrderList(res?.data);
          setAdCustomerListOrderLoader(false);
        } else {
          setAdCustomerListOrderLoader(false);
        }
      })
      .catch((err) => {
        setAdCustomerListOrderLoader(false);
      });
  }
  async function fetchAdCustomerList() {
    setAdCustomerListLoader(true);
    await getAll(`customers.php?action=customerlist`, adCustomerListFilter)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdCustomerList(res?.data);
          setAdCustomerListLoader(false);
        } else {
          setAdCustomerListLoader(false);
        }
      })
      .catch((err) => {
        setAdCustomerListLoader(false);
      });
  }

  return (
    <Context.Provider
      value={{
        ...props,
        adCustomerList,
        setAdCustomerList,
        adCustomerListLoader,
        setAdCustomerListLoader,
        adCustomerListFilter,
        setAdCustomerListFilter,
        fetchAdCustomerList,
        adCustomerOrderList,
        setAdCustomerOrderList,
        adCustomerListOrderLoader,
        setAdCustomerListOrderLoader,
        adCustomerListOrderFilter,
        setAdCustomerListOrderFilter,
        fetchAdCustomerOrderList,
        adCustomerOverview,
        setAdCustomerOverview,
        adCustomerOverviewLoader,
        setAdCustomerOverviewLoader,
        fetchAdCustomerOverview,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AdminCustomerProvider;
