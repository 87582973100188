import React from "react";
import { Navigate } from "react-router-dom";

export const AuthRouter = ({ children }) => {
  const isAdmin = localStorage.getItem("nattu_admin_login") === "true";
  const adminToken = localStorage.getItem("nattu_admin_token");
  if (isAdmin && adminToken !== "") {
    return children;
  } else {
    return <Navigate to="/admin/login" />;
  }
};

export const Redirect = ({ children }) => {
  const isAdmin = localStorage.getItem("nattu_admin_login") === "true";
  const adminToken = localStorage.getItem("nattu_admin_token");
  if (!isAdmin || adminToken === "") {
    return children;
  } else {
    return <Navigate to="/admin" />;
  }
};
