export const Custom = {
  colorPrimary: "#6C9C2D", //#005ce8 //#6C9C2D
  colorError: "#dc3545",
  colorSuccess: "#28a745",
  borderRadius: 7,
  borderColor: "#f0f0f0",
  fontFamily: "sans-serif",
  colorLight: "#949494",
  colorBgContainer: "#fff",
  colorBg: "#f8f8f8",
  colorBgLayout: "#fff",
};

export const Styles = {
  colorPrimary: "#6C9C2D",
  colorBlue: "#005ce8",
  colorError: "#dc3545",
  colorSuccess: "#28a745",
  theme_bg: "#f5f5f5",
  borderColor: "#f0f0f0",
  gray: "#858a99",
  colorLight: "#949494",
  fontFamily: "Poppins-Regular",
  input_size: "large",
  table_size: "middle",
};
