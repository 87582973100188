import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import WebMyAccountProvider from "../Provider/WebMyAccountProvider";
export const WebAuthRouter = ({ children }) => {
  const { setWebLoginPopup } = useContext(WebMyAccountProvider);
  const isAdmin = localStorage.getItem("nattu_customer_login") === "true";
  const adminToken = localStorage.getItem("nattu_customer_token");
  if (isAdmin && adminToken !== "") {
    return children;
  } else {
    setWebLoginPopup(true);
    return <Navigate to="/" />;
  }
};

export const WebRedirect = ({ children }) => {
  const isAdmin = localStorage.getItem("nattu_customer_login") === "true";
  const adminToken = localStorage.getItem("nattu_customer_token");
  if (!isAdmin || adminToken === "") {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};
