import React, { useState } from "react";
import Context from "./index";
import { getAll, create, getSingle } from "../../Api/AuthApi";
import { message, Form } from "antd";
const RatingsProvider = ({ children, ...props }) => {
  const [adRatingsList, setAdRatingsList] = useState([]);
  const [adRatingsListLoader, setAdRatingsListLoader] = useState(false);
  const [adRatingsListFilter, setAdRatingsListFilter] = useState({
    limit: 10,
    page: 1,
    name: "",
  });
  const [adRatingProductList, setAdRatingProductList] = useState([]);
  const [adRatingProductListLoader, setAdRatingProductListLoader] =
    useState(false);
  const [adAddRatingLoader, setAdAddRatingLoader] = useState(false);
  const [adAddRatingPopup, setAdAddRatingPopup] = useState(false);
  const [adAddRatingForm] = Form.useForm();
  const [adRatingSingle, setAdRatingSingle] = useState({});
  const [adRatingSingleLoader, setAdRatingSingleLoader] = useState(false);
  const [adViewRatingPopup, setAdViewRatingPopup] = useState(false);
  const [adDeleteReviewLoader, setAdDeleteReviewLoader] = useState(false);
  const [adReviewVerifyLoader, setAdReviewVerifyLoader] = useState(false);
  async function adRatingsVerify(id) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdReviewVerifyLoader(true);
    await getAll(`rating.php?action=verifyreview&rating_id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          adRatingsListFetch();
          setAdReviewVerifyLoader(false);
        } else {
          setAdRatingsList([]);
          setAdReviewVerifyLoader(false);
        }
      })
      .catch((err) => {
        setAdRatingsList([]);
        setAdReviewVerifyLoader(false);
      });
  }
  async function adRatingsListFetch() {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdRatingsListLoader(true);
    await getAll("rating.php?action=ratinglist", adRatingsListFilter)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdRatingsList(res?.data);
          setAdRatingsListLoader(false);
        } else {
          setAdRatingsList([]);
          setAdRatingsListLoader(false);
        }
      })
      .catch((err) => {
        setAdRatingsList([]);
        setAdRatingsListLoader(false);
      });
  }
  async function adRatingsProductListFetch() {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdRatingProductListLoader(true);
    await getAll("rating.php?action=productdropdown")
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdRatingProductList(res?.data);
          setAdRatingProductListLoader(false);
        } else {
          setAdRatingProductList([]);
          setAdRatingProductListLoader(false);
        }
      })
      .catch((err) => {
        setAdRatingProductList([]);
        setAdRatingProductListLoader(false);
      });
  }

  async function adAddRating(data = {}) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdAddRatingLoader(true);
    await create("rating.php?action=ratingcreate", data)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdAddRatingPopup(false);
          adAddRatingForm.resetFields();
          setAdAddRatingLoader(false);
          adRatingsListFetch();
        } else {
          message.error(res?.message);
          setAdAddRatingLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdAddRatingLoader(false);
      });
  }
  async function adRatingSingleFetch(id) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdRatingSingleLoader(true);
    await getSingle(`rating.php?action=ratingsingle&id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdRatingSingle(res?.data);
          setAdRatingSingleLoader(false);
        } else {
          setAdRatingSingle({});
          setAdRatingSingleLoader(false);
        }
      })
      .catch((err) => {
        setAdRatingSingle({});
        setAdRatingSingleLoader(false);
      });
  }
  async function adRatingDelete(id) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdDeleteReviewLoader(true);
    await getSingle(`rating.php?action=ratingdelete&rating_id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdDeleteReviewLoader(false);
          adRatingsListFetch();
        } else {
          setAdDeleteReviewLoader(false);
        }
      })
      .catch((err) => {
        setAdDeleteReviewLoader(false);
      });
  }
  return (
    <Context.Provider
      value={{
        ...props,
        adRatingsList,
        setAdRatingsList,
        adRatingsListLoader,
        setAdRatingsListLoader,
        adRatingsListFilter,
        setAdRatingsListFilter,
        adRatingsListFetch,
        adRatingProductList,
        setAdRatingProductList,
        adRatingProductListLoader,
        setAdRatingProductListLoader,
        adRatingsProductListFetch,
        adAddRating,
        adAddRatingLoader,
        setAdAddRatingLoader,
        adAddRatingPopup,
        setAdAddRatingPopup,
        adAddRatingForm,
        adRatingSingle,
        setAdRatingSingle,
        adRatingSingleLoader,
        setAdRatingSingleLoader,
        adViewRatingPopup,
        setAdViewRatingPopup,
        adRatingSingleFetch,
        adRatingDelete,
        adDeleteReviewLoader,
        setAdDeleteReviewLoader,
        adRatingsVerify,
        adReviewVerifyLoader,
        setAdReviewVerifyLoader,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default RatingsProvider;
